import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  AppBar,
  Box,
  Button,
  Container,
  Toolbar,
  Typography,
  Popover,
} from "@material-ui/core"

import MenuIcon from "@material-ui/icons/Menu"
import AddIcon from "@material-ui/icons/Add"
import SearchIcon from "@material-ui/icons/Search"
import MoreIcon from "@material-ui/icons/MoreVert"

const useStyles = makeStyles(theme => ({
  title: {
    padding: theme.spacing(1, 0, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: "auto",
    bottom: 0,
    backgroundColor: theme.palette.common.black,
  },
  grow: {
    flexGrow: 1,
  },
  innerBox: {
    display: "flex",
    flexDirection: "row",
  },
  menu: {
    backgroundColor: theme.palette.common.black,
    color: "#ffffff",
    height: "70vh",
    width: "275px",
  },
  boxTitle: {
    color: theme.palette.common.white,
    fontSize: "20px",
    letterSpacing: "2px",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
}))

export default function BottomAppBar() {
  // export default function BottomAppBar({ menuOpen, setMenuOpen }) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleMenu = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar>
          <Box component={Container} className={classes.innerBox}>
            <Button
              startIcon={<MenuIcon />}
              color="inherit"
              aria-label="open drawer"
              // onClick={() => setMenuOpen(!menuOpen)}
              onClick={handleMenu}
            >
              Gallery
            </Button>
            <Popover
              elevation={0}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
            >
              <Box className={classes.menu}>
                <Box mb={2}>
                  <Typography
                    component="h1"
                    className={classes.boxTitle}
                    color="inherit"
                    gutterBottom
                  >
                    Gallery Menu
                  </Typography>
                </Box>
              </Box>
            </Popover>
            <div className={classes.grow} />
            <Typography variant="body1" className={classes.title}>
              Embroidered Patches
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  )
}
