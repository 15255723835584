import React, { useState, useContext, useEffect } from "react"
import gql from "graphql-tag"
import { useQuery } from "@apollo/client"

import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import {
  Image as ImageIcon,
  Edit as EditIcon,
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon,
  X as CancelIcon,
} from "react-feather"
import Pagination from "@material-ui/lab/Pagination"

import { ImageSearch } from "@material-ui/icons"
import GalleryCard from "./GalleryCard"

// import NoPrivateItems from "./NoPrivateItems"

const GalleryList = ({ classes, setCurrentTab }) => {
  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item container justify="space-between" xs={12}></Grid>
          <>
            <Grid item xs={12} sm={4}>
              <GalleryCard />
            </Grid>
          </>

          <Grid item container xs={12} justify="flex-end" alignItems="flex-end">
            <Grid item xs={12} sm={4}>
              {/* <Box className={classes.pagination}>
                {myPrivateItems.pages > 1 && (
                  <Pagination
                    count={myPrivateItems.pages}
                    page={myPrivateItems.page}
                    onChange={handleChange}
                    hideNextButton={!myPrivateItems.hasNext}
                    hidePrevButton={!myPrivateItems.hasPrev}
                    shape="rounded"
                    variant="outlined"
                    size="medium"
                    color="secondary"
                  />
                )}
              </Box> */}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: "grid",
  },
  pagination: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "spaceBetween",
  },
  searchField: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    minWidth: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
})

export default withStyles(styles)(GalleryList)
