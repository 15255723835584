import React, { useState, Fragment } from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonBase,
  ButtonGroup,
  Card,
  Container,
  Divider,
  Grid,
  IconButton,
  Icon,
  MobileStepper,
  Paper,
  Typography,
} from "@material-ui/core"

import { withStyles, useTheme } from "@material-ui/core/styles"
import "react-perfect-scrollbar/dist/css/styles.css"
import PerfectScrollbar from "react-perfect-scrollbar"
import Layout from "../components/Layout/layout"
import GalleryList from "../components/Gallery/GalleryList"
import GalleryPatches from "../components/Gallery/GalleryPatches"
import GalleryAll from "../components/Gallery/GalleryAll"
// import GalleryPatches from "../components/Gallery/GallerPatches"
import ItemSide from "../components/Layout/ItemSide"
import BottomAppBar from "../components/Layout/BottomAppBar"
import GalleryNavCard from "../components/Gallery/GalleryNavCard"
import GallerySlider from "../components/Gallery/GallerySlider"
import FileCopyIcon from "@material-ui/icons/FileCopyOutlined"
import SaveIcon from "@material-ui/icons/Save"
import PrintIcon from "@material-ui/icons/Print"
import ShareIcon from "@material-ui/icons/Share"
import FavoriteIcon from "@material-ui/icons/Favorite"

import FolderIcon from "@material-ui/icons/Folder"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
const actions = [
  { icon: <FileCopyIcon />, name: "Copy" },
  { icon: <SaveIcon />, name: "Save" },
  { icon: <PrintIcon />, name: "Print" },
  { icon: <ShareIcon />, name: "Share" },
  { icon: <FavoriteIcon />, name: "Like" },
]

const StyledBadge = withStyles(theme => ({
  badge: {
    // right: -3,
    top: 40,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge)

const Gallery = ({ classes, data }) => {
  const theme = useTheme()
  const items = "embroidered-patches"
  const category = "patches"
  const {
    mdx: {
      frontmatter: { title, slug, tagline },
      body,
    },
  } = data

  const description = data.mdx.exports.description
  const galleryImages = data.mdx.exports.galleryImages
  const categories = [...new Set(galleryImages.map(x => x.category))]

  const maxSteps = galleryImages.length
  const [activeStep, setActiveStep] = useState(0)

  const [currentImage, setCurrentImage] = useState(galleryImages[0])

  const [menuOpen, setMenuOpen] = useState(false)

  const [currentCategory, setCurrentCategory] = useState(categories[0])
  const [filteredGallery, setFilteredGallery] = useState("")

  const smGrid = () => {
    let gridSize = menuOpen ? 9 : 12
    return { gridSize }
  }

  const handleImageChange = gal => {
    setCurrentImage(gal)
    setActiveStep(gal.id - 1)
  }
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
    setCurrentImage(galleryImages => galleryImages.id === currentImage.id + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
    // setCurrentImage(galleryImages[currentImage.id - 1])
  }
  const handleBackCategory = () => {
    let currentCategoryIndex = categories.indexOf(currentCategory)

    if (currentCategoryIndex >= 0) {
      let lastCategory = categories[currentCategoryIndex - 1]
      setCurrentCategory(lastCategory)
      let newGallery = galleryImages.filter(x => x.category === lastCategory)
      let newStep = newGallery[0].id
      setActiveStep(newStep)
      setCurrentImage(newGallery[0])
    } else {
      let lastCategory = categories[categories.length - 1]
      setCurrentCategory(lastCategory)
      let newGallery = galleryImages.filter(x => x.category === lastCategory)
      let newStep = newGallery[0].id
      setActiveStep(newStep)
      setCurrentImage(newGallery[0])
    }
  }
  const handleNextCategory = () => {
    let currentCategoryIndex = categories.indexOf(currentCategory)

    if (
      currentCategoryIndex >= 0 &&
      currentCategoryIndex < categories.length - 1
    ) {
      var nextCategory = categories[currentCategoryIndex + 1]
      setCurrentCategory(nextCategory)
      let newGallery = galleryImages.filter(x => x.category === nextCategory)
      setActiveStep(newGallery[0].id)
    }

    let newGallery = galleryImages.filter(x => x.category === nextCategory)
    setFilteredGallery(newGallery)
    setActiveStep(newGallery[0].id)
    setCurrentImage(newGallery[0])
  }
  return (
    <>
      <Layout>
        <Container className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xxs={12} xs={12} sm={8}>
              <Typography className={classes.itemPageHeader}>
                Gallery
              </Typography>
            </Grid>
            <Grid item xxs={12} xs={12} sm={4}>
              <Box className={classes.categoryNav}>
                <IconButton
                  className={classes.stepper}
                  edge="start"
                  size="small"
                  onClick={handleBackCategory}
                  disabled={categories.indexOf(currentCategory) === 0}
                >
                  <StyledBadge
                    badgeContent={
                      categories[categories.indexOf(currentCategory) - 1]
                    }
                    color="secondary"
                  >
                    <KeyboardArrowLeft />
                  </StyledBadge>
                </IconButton>

                <Typography display="inline" className={classes.galleryTitle}>
                  {currentImage.category}
                </Typography>

                <IconButton
                  className={classes.stepper}
                  edge="start"
                  size="small"
                  onClick={handleNextCategory}
                  disabled={
                    categories.indexOf(currentCategory) ===
                    categories.length - 1
                  }
                >
                  <StyledBadge
                    badgeContent={
                      categories[categories.indexOf(currentCategory) + 1]
                    }
                    color="secondary"
                  >
                    <KeyboardArrowRight />
                  </StyledBadge>
                </IconButton>
              </Box>
            </Grid>
            <Grid item xxs={12} xs={12} sm={8}>
              <GallerySlider
                galleryImages={galleryImages}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
              />
              {/* <ButtonBase>
                <Box className={classes.imageBox}>
                  <Image
                    fluid={currentImage}
                    //   alt={`${currentImage.category}: ${currentImage.name} by ICON Emblem`}
                    className={classes.itemImage}
                  />

                  {currentImage.name}
                  <Typography>{currentImage.name}</Typography>
                </Box>
              </ButtonBase> */}
              {/* <ButtonGroup>
                <Button>Show All</Button>
                <Button>Filter</Button>
              </ButtonGroup> */}
              {/* <GalleryMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} /> */}
            </Grid>
            <Grid item xxs={12} xs={12} sm={4}>
              {/* <GalleryList /> */}

              <Paper square variant="outlined" className={classes.galListBox}>
                <Box className={classes.sideGalHeader}>
                  {/* <Box className={classes.categoryNav}>
                    <IconButton
                      className={classes.stepper}
                      edge="start"
                      size="small"
                      onClick={handleBackCategory}
                    >
                      <KeyboardArrowLeft />
                    </IconButton>

                    <Typography
                      display="inline"
                      className={classes.galleryTitle}
                    >
                      {currentImage.category}
                    </Typography>

                    <IconButton
                      className={classes.stepper}
                      edge="start"
                      size="small"
                      onClick={handleNextCategory}
                    >
                      <KeyboardArrowRight />
                    </IconButton>
                  </Box>
                  <Typography>activeStep: {activeStep}</Typography> */}
                  <Card square className={classes.captionCard}>
                    <Typography>{currentImage.name}</Typography>
                    <Typography>{currentImage.caption}</Typography>
                  </Card>
                  <MobileStepper
                    className={classes.stepper}
                    steps={maxSteps}
                    position="static"
                    variant="text"
                    activeStep={activeStep}
                    nextButton={
                      <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                        className={classes.stepper}
                      >
                        Next
                        {theme.direction === "rtl" ? (
                          <KeyboardArrowLeft />
                        ) : (
                          <KeyboardArrowRight />
                        )}
                      </Button>
                    }
                    backButton={
                      <Button
                        size="small"
                        onClick={handleBack}
                        disabled={activeStep === 0}
                        className={classes.stepper}
                      >
                        {theme.direction === "rtl" ? (
                          <KeyboardArrowRight />
                        ) : (
                          <KeyboardArrowLeft />
                        )}
                        Back
                      </Button>
                    }
                  />
                </Box>

                <Box className={classes.menuBox}>
                  <PerfectScrollbar options={{ suppressScrollX: true }}>
                    <Box p={2}>
                      {galleryImages
                        .filter(el => el.category === currentCategory)
                        .map((gal, index) => {
                          const image = gal.img.childImageSharp.fluid
                          let activeId = activeStep + 1
                          return (
                            <Box className={classes.buttonBox} key={gal.id}>
                              <ButtonBase
                                fullWidth
                                className={
                                  gal.id === activeId
                                    ? classes.activeListButton
                                    : classes.listButton
                                }
                                onClick={() => handleImageChange(gal)}
                              >
                                <Box className={classes.buttonImage}>
                                  <Image
                                    className={classes.listImage}
                                    fluid={image}
                                  />
                                </Box>
                                <Box className={classes.buttonText}>
                                  <Typography>{gal.name}</Typography>
                                  <Typography>{gal.category}</Typography>
                                  <Typography>{gal.caption}</Typography>
                                </Box>
                              </ButtonBase>
                              <Divider fullWidth light />
                            </Box>
                          )
                        })}
                    </Box>
                  </PerfectScrollbar>
                </Box>
              </Paper>
              {/* {gallerySwitch(item)} */}
            </Grid>
          </Grid>
        </Container>
      </Layout>
      {/* <BottomAppBar menuOpen={menuOpen} setMenuOpen={setMenuOpen} /> */}
    </>
  )
}
const styles = theme => ({
  root: {
    minWidth: 225,
    flexGrow: 1,
    margin: theme.spacing(0, 0.5, 0.5, 0.5),
  },
  container: {
    marginTop: 25,
  },
  captionCard: {
    minHeight: "220px",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.almostBlack,
    border: "1px solid #a6a6a6",
    color: theme.palette.common.white,
  },
  itemImage: {
    maxWidth: "100%",
  },
  itemPageHeader: {
    fontSize: "42px",
    letterSpacing: "3px",
    textTransform: "uppercase",
  },
  tagline: {
    fontSize: "32px",
    letterSpacing: "3px",
    textTransform: "uppercase",
  },
  summary: {
    fontSize: "18px",
    letterSpacing: "0.1px",
    marginTop: theme.spacing(1),
  },
  spacer: {
    marginTop: theme.spacing(2),
  },
  grow: {
    flexgrow: 1,
  },
  commonButton: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    width: "100%",
    // margin: theme.spacing(1),
    fontSize: "18px",
    textTransform: "uppercase",
    letterSpacing: "2px",
  },

  stepper: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  exampleWrapper: {
    position: "relative",
    marginTop: theme.spacing(3),
    height: 380,
  },

  imageBox: {
    minHeight: "60px",
    maxheight: "120px",
    // backgroundColor: "#000000",
    // color: "#ffffff",
  },
  listImage: {
    marginLeft: theme.spacing(2),
    width: "100%",
    height: "100%",
    objectFit: "contain",
    borderRadius: "10px",
  },
  buttonImage: {
    width: "100px",
    height: "100px",
    display: "flex",
    // width: "auto",
    // minHeight: "40px",
    // maxWidth: "100px",
    // maxHeight: "80px",
    // marginBottom: theme.spacing(2),
  },
  galListBox: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.black,
    // height: "750px",
    display: "block",

    overflow: "hidden",
  },
  buttonBox: {
    width: "100%",
    height: "140px",
    display: "flex",

    // borderBottom: "1px solid #ffffff",

    marginBottom: theme.spacing(2),
  },
  listButton: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // padding: theme.spacing(2),
    width: "100%",
    height: "100%",
    // maxHeight: theme.spacing(10),
    // marginBottom: theme.spacing(2),
    "&:hover": {
      // textDecoration: "none",
      backgroundColor: theme.palette.common.almostBlack,
    },
  },
  activeListButton: {
    backgroundColor: theme.palette.common.almostBlack,
    color: theme.palette.common.white,

    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // padding: theme.spacing(2),
    width: "100%",
    height: "100%",
    // maxHeight: theme.spacing(10),
    // marginBottom: theme.spacing(2),
    "&:hover": {
      // textDecoration: "none",
      backgroundColor: theme.palette.common.almostBlack,
    },
  },

  sideGalHeader: {
    color: "#ffffff",
  },
  galleryTitle: {
    color: theme.palette.common.black,
    fontSize: "22px",
    letterSpacing: "1px",
    textTransform: "uppercase",
  },
  menuBox: {
    height: "100%",
    border: "1px solid #ffffff",
    marginBottom: theme.spacing(2),
  },
  buttonText: {
    maxWidth: "200px",
  },
  categoryNav: {
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #ffffff",
    padding: theme.spacing(1),
  },
  description: {
    minHeight: "150px",
    padding: theme.spacing(2),
  },
})
// TODO: Use pages, add galleryjs there, Make the GalleryMenu component pull the query. Bring slug into gallerymenu as a prop to select a specific gallery query.
// TODO: Use media query to pick a gallery style. Mobile menu is a scroll.
// TODO: Create an alternate view where user can simply scroll through. Use this for "all".

export const query = graphql`
  query GetGalleryData($slug: String) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        category
        date(formatString: "MMMM Do, YYYY")
        slug
        tagline
      }
      id
      body
      exports {
        description
        galleryImages {
          id
          name
          caption
          img {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          category
        }
      }
    }
  }
`
export default withStyles(styles)(Gallery)
