import React, { useState } from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import MobileStepper from "@material-ui/core/MobileStepper"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
// import SwipeableViews from "react-swipeable-views"
// import { autoPlay } from "react-swipeable-views-utils"
import Image from "gatsby-image"

import useMediaQuery from "@material-ui/core/useMediaQuery"
import { relativeTimeRounding } from "moment"
// const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

// const tutorialSteps = [
//   {
//     label: "San Francisco – Oakland Bay Bridge, United States",
//     imgPath:
//       "https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60",
//   },
//   {
//     label: "Bird",
//     imgPath:
//       "https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60",
//   },
//   {
//     label: "Bali, Indonesia",
//     imgPath:
//       "https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250&q=80",
//   },
//   {
//     label: "NeONBRAND Digital Marketing, Las Vegas, United States",
//     imgPath:
//       "https://images.unsplash.com/photo-1518732714860-b62714ce0c59?auto=format&fit=crop&w=400&h=250&q=60",
//   },
//   {
//     label: "Goč, Serbia",
//     imgPath:
//       "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",
//   },
// ]

const useStyles = makeStyles(theme => ({
  root: {
    // maxWidth: 1000,

    flexGrow: 1,
    position: "relative",
  },
  contentBox: {},
  contentBoxMob: {
    height: "50vh",
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    display: "block",
    // maxWidth: 1000,
    overflow: "hidden",
    width: "100%",
    border: "2px solid #555",
  },
  mobImg: {
    maxHeight: "50vh",
    border: "2px solid #555",
    // display: "block",
    // maxWidth: 1000,
    // overflow: "hidden",
    width: "100%",
  },
}))

const GallerySlider = ({ galleryImages, activeStep, setActiveStep }) => {
  const classes = useStyles()
  const theme = useTheme()
  const smallUp = useMediaQuery(theme.breakpoints.up("md"))
  const maxSteps = galleryImages.length

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleStepChange = step => {
    setActiveStep(step)
  }

  return (
    <div className={classes.root}>
      {/* <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {galleryImages.map((gal, index) => {
          const image = gal.img.childImageSharp.fluid
          return (
            <div
              key={gal.id}
              className={smallUp ? classes.contentBox : classes.contentBoxMob}
            >
              {Math.abs(activeStep - index) <= 2 ? (
                <Image
                  className={smallUp ? classes.img : classes.mobImg}
                  //   className={classes.listImage}
                  fluid={image}
                />
              ) : null}
            </div>
          )
        })}
      </SwipeableViews> */}
      {/* <MobileStepper
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      /> */}
    </div>
  )
}

export default GallerySlider
